import { SwapWidget } from '@uniswap/widgets'

// import '@uniswap/widgets/fonts.css'

function AppTest() {

    const MY_TOKEN_LIST = [
        {
        "name": "Chad Coin",
        "address": "0x6B89B97169a797d94F057F4a0B01E2cA303155e4",
        "symbol": "CHAD",
        "decimals": 18,
        "chainId": 1,
        "logoURI": "https://cdn.prod.website-files.com/665e3be0948b181c43ae2e5c/66601a4e8869f60ec5a6ec9d_Vectors-Wrapper.svg"
      },
        {
        "name": "Dai Stablecoin",
        "address": "0x6B175474E89094C44Da98b954EedeAC495271d0F",
        "symbol": "DAI",
        "decimals": 18,
        "chainId": 1,
        "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png"
      },
        {
        "name": "Tether USD",
        "address": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
        "symbol": "USDT",
        "decimals": 6,
        "chainId": 1,
        "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png"
      },
      {
        "name": "USD Coin",
        "address": "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
        "symbol": "USDC",
        "decimals": 6,
        "chainId": 1,
        "logoURI": "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png"
      },
    ]

  //   declare const defaultTheme: {
  //     accent: string;
  //     container: string;
  //     module: string;
  //     interactive: string;
  //     outline: string;
  //     dialog: string;
  //     primary: string;
  //     onAccent: string;
  //     secondary: string;
  //     hint: string;
  //     onInteractive: string;
  //     active: string;
  //     success: string;
  //     warning: string;
  //     error: string;
  //     currentColor: "currentColor";
  //     borderRadius: number;
  //     fontFamily: {
  //         font: string;
  //         variable: string;
  //     };
  //     fontFamilyCode: string;
  //     tokenColorExtraction: boolean;
  // };

    const theme = {
      accent: "#FF004E",
      interactive: "#FF004E",
      onInteractive: "#FFFFFF",
    }

  return(
    <div className="Uniswap">
      {/* <Page /> */}
        <SwapWidget
          // jsonRpcUrlMap={"https://rpc.mevblocker.io/"}
          tokenList={MY_TOKEN_LIST}
          defaultOutputTokenAddress={'0x6B89B97169a797d94F057F4a0B01E2cA303155e4'}
          width={"100%"} // Custom width in pixels
          theme={theme}
        />
    </div>
  )
}

export default AppTest