import React from 'react'
import ReactDOM from 'react-dom'
// import './index.css'
// import App from './components/App'
import AppTest from './components/AppTest'

ReactDOM.render(
  <React.StrictMode>
    <AppTest />
  </React.StrictMode>,
  document.getElementById('root')
)
